import { SvgIcon } from "@mui/material";
import React from "react";

function ChannelIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
         >
            <mask
               id="mask0_333_25"
               style={{maskType:"alpha"}}
               maskUnits="userSpaceOnUse"
               x="0"
               y="0"
               width="24"
               height="24"
            >
               <rect width="24" height="24" fill="url(#pattern0_333_25)" />
            </mask>
            <g mask="url(#mask0_333_25)">
               <rect x="-52" y="-7" width="100" height="100" fill="currentColor" />
            </g>
            <defs>
               <pattern
                  id="pattern0_333_25"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
               >
                  <use
                     xlinkHref="#image0_333_25"
                     transform="scale(0.0416667)"
                  />
               </pattern>
               <image
                  id="image0_333_25"
                  width="24"
                  height="24"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFGSURBVHgB7ZVLTsMwEIZn7Aax5AaEJQsEvUFvADcoLJF4KCdIcwKQIsQ23IAbcIRQWLBsuEGWiDQephRMbRo5SR+rflIke+yZ35OZOAAbHKBtEPHrNQANeLgDTUDIgDBRlweRaTaDhz/B20MUqKvDWx1zdm2irsblHu96hrYghrPTzu/Au0uPSiWfEOGxLMoz0ZEptMN4tTqDkkRvskiKehB022dgoTNQhUqklDmiyGBaaFgGWoBPnVM89Hl0zM+JtiMkXLj3Cn/ej32oJTCNFtrn3pJe9HG+n81z3r5/8z/HRROB/3CAkYhfqtZc7mabrgJnBt/fRdDNDONN6nMbj6AGTgHpyZCLbxQZEXepZpM5BTjQqX1lUYMOXnkN1i6Qw6IQZdUCRBEsCsKDObUQ8XDA11CfW8WHJhBnLyhSF3//gg21+AKuMWuxdNyIQwAAAABJRU5ErkJggg=="
               />
            </defs>
         </svg>
      </SvgIcon>
   );
}

export default ChannelIcon;
