import { SvgIcon } from "@mui/material";
import React from "react";

function ConversationLabelIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H15C15.3167 4 15.6167 4.071 15.9 4.213C16.1833 4.355 16.4167 4.55067 16.6 4.8L22 12L16.6 19.2C16.4167 19.45 16.1833 19.646 15.9 19.788C15.6167 19.93 15.3167 20.0007 15 20H4Z"
               fill="currentColor"
            />
         </svg>
      </SvgIcon>
   );
}

export default ConversationLabelIcon;
