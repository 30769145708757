import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../config/axios";

export const getAllLabels = createAsyncThunk(
   "insights/getAllLabels",
   async () => {
      try {
         const response = await API.get(`/label`);
         return response.data.data;
      } catch (err) {
         throw err;
      }
   }
);

const initialState = {
   loading: null,
   error: null,
   labels: [],
};
const labelSlice = createSlice({
   name: "labels",
   initialState,
   reducers: {},

   extraReducers: (builder) => {
      builder
         .addCase(getAllLabels.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllLabels.fulfilled, (state, action) => {
            state.loading = false;
            state.labels = action.payload;
         })
         .addCase(getAllLabels.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },
});

export default labelSlice.reducer;
export const {} = labelSlice.actions;
