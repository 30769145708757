import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import Body from "./routes/index";
import { Provider, useDispatch } from "react-redux";
import appStore from "./store/appStore";
import { ThemeContextProvider } from "./theme";
import { Suspense, useEffect } from "react";
import FullScreenSpinner from "./components/common/FullScreenSpinner";
import { loadUserFromStorage } from "./store/slices/authSlice";

function App() {
  
   return (
      <Provider store={appStore}>
         <div className="App">
            <ThemeContextProvider>
               <Suspense fallback={<FullScreenSpinner />}>
                  <Body />
               </Suspense>
            </ThemeContextProvider>
         </div>
      </Provider>
   );
}

export default App;
library.add(fab, fas, far);
