import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./slices/userSlice";
import sideBarReducer from "./slices/sideBarSlice";
import chatReducer from "./slices/chatSlice";
import messgeReducer from "./slices/messageSlice";
import notificationReducer from "./slices/notificationSlice";
import bulkSendReportReducer from "./slices/bulkSendReportSlice";
import contactReducer from "./slices/contactSlice";
import templateReducer from "./slices/templateSlice";
import insightsReducer from "./slices/insightsSlice";
import labelReducer from "./slices/labelSlice";
import groupReducer from "./slices/groupSlice";
import scheduledReducer from "./slices/scheduledMessageSlice";
import authReducer from "./slices/authSlice";


const appStore = configureStore({
   reducer: {
      auth: authReducer,
      user: userReducer,
      sidebar: sideBarReducer,
      chat: chatReducer,
      messages: messgeReducer,
      notification: notificationReducer,
      bulkSend: bulkSendReportReducer,
      contacts: contactReducer,
      template: templateReducer,
      insights: insightsReducer,
      labels: labelReducer,
      group: groupReducer,
      scheduledMessage: scheduledReducer,
   },
   devTools:window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
});
export default appStore;
