import { SvgIcon } from "@mui/material";
import React from "react";

function SettingsIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M7.19287 0.21261C8.49419 -0.0701948 9.83791 -0.0708757 11.1395 0.210611C11.2262 0.80377 11.4408 1.36905 11.7673 1.86443C12.0938 2.35982 12.5239 2.77256 13.0255 3.07199C13.5267 3.37246 14.0869 3.55195 14.6643 3.59713C15.2416 3.64231 15.8214 3.55201 16.3605 3.33294C17.2464 4.36078 17.9174 5.56749 18.3314 6.87717C17.8793 7.25167 17.5144 7.72694 17.2639 8.26766C17.0134 8.80839 16.8836 9.40067 16.8843 10.0005C16.8843 11.2642 17.4497 12.391 18.3333 13.1238C17.9169 14.4324 17.245 15.6382 16.3595 16.6661C15.8206 16.4471 15.241 16.3569 14.6638 16.4021C14.0866 16.4473 13.5266 16.6267 13.0255 16.927C12.5243 17.2263 12.0946 17.6388 11.7682 18.1338C11.4419 18.6288 11.2273 19.1937 11.1405 19.7864C9.83927 20.0699 8.49556 20.0712 7.19384 19.7904C7.10754 19.1967 6.89314 18.6308 6.5666 18.1349C6.24007 17.639 5.8098 17.2257 5.30783 16.926C4.80653 16.6257 4.24636 16.4464 3.66897 16.4014C3.09158 16.3564 2.51183 16.4468 1.97283 16.6661C1.08684 15.6379 0.415794 14.4309 0.00192951 13.1208C0.453792 12.7465 0.818516 12.2716 1.06903 11.7312C1.31954 11.1909 1.44941 10.599 1.44899 9.9995C1.44947 9.39951 1.31944 8.80713 1.06858 8.26639C0.817715 7.72565 0.452471 7.25047 0 6.87617C0.416435 5.56758 1.08831 4.36182 1.9738 3.33394C2.51273 3.55285 3.09234 3.64307 3.66955 3.59789C4.24677 3.55272 4.80673 3.3733 5.30783 3.07299C5.80904 2.77365 6.23876 2.36117 6.5651 1.86616C6.89143 1.37115 7.106 0.805324 7.19287 0.21261ZM9.16667 12.9999C9.93424 12.9999 10.6704 12.6838 11.2131 12.1214C11.7559 11.5589 12.0608 10.796 12.0608 10.0005C12.0608 9.20502 11.7559 8.44212 11.2131 7.87964C10.6704 7.31715 9.93424 7.00115 9.16667 7.00115C8.3991 7.00115 7.66296 7.31715 7.12021 7.87964C6.57745 8.44212 6.27254 9.20502 6.27254 10.0005C6.27254 10.796 6.57745 11.5589 7.12021 12.1214C7.66296 12.6838 8.3991 12.9999 9.16667 12.9999Z"
               fill="currentColor"
            />
         </svg>
      </SvgIcon>
   );
}

export default SettingsIcon;
