import { SvgIcon } from "@mui/material";
import React from "react";

function ScheduledMessageIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
         >
            <mask
               id="mask0_333_16"
               style={{maskType:"alpha"}}
               maskUnits="userSpaceOnUse"
               x="0"
               y="0"
               width="24"
               height="24"
            >
               <rect width="24" height="24" fill="url(#pattern0_333_16)" />
            </mask>
            <g mask="url(#mask0_333_16)">
               <rect x="-45" y="-42" width="100" height="100" fill="currentColor" />
            </g>
            <defs>
               <pattern
                  id="pattern0_333_16"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
               >
                  <use
                     xlinkHref="#image0_333_16"
                     transform="scale(0.0416667)"
                  />
               </pattern>
               <image
                  id="image0_333_16"
                  width="24"
                  height="24"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGSSURBVHgB5VRLTgJBEK3qQeKSI8DSDTonEE6gR9ClEYU5AifgowlLOYI3EE7gOBuXzBEIYUHE7rKrYT4ZndgixEQq6dRMV9V7NfUZgL8UcRcQn03txgd2LBgz3QcTICjDNgQhVI1qxeDGl9sCz2DtvER7SoAIIyDyigVZUTdV5OMI6SLBpb4P074F+IkQTEFQWzaOu/y6SJmW166vFZ+h6L+04mSih+8WhsGVlHXwXL/0MCnN5vMmAtV0UG0N5SNRT95Wh+kwewIkT+nMDwev5Te5fModa70DReegvrg6Cg0u2Iiuq1qXJQse9SDxhVUCnUnJmgAB26ydfnBhtZDaRxRmLWsC6UjfxAlsgqXopE6tCWA1IVyqk1yfzHhGzf/1onHTWSup6lkSe4LBc5mV7uQoa+KGGhLPDYXAx8RCvjWBkOLchACNPxnXUyP6QUcRJAtG2LMm0M5nrNW76n5VBjNZmICzT7RwIn2ZR8ANM+vvudO8WqdxjE+UHGwovBMkqAmEZrL4B0hEY/OVOhH4N/IBsXjJ38y4q/wAAAAASUVORK5CYII="
               />
            </defs>
         </svg>
      </SvgIcon>
   );
}

export default ScheduledMessageIcon;
