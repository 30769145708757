import {
   Avatar,
   Box,
   Dialog,
   DialogTitle,
   ListItem,
   TextField,
   Typography,
} from "@mui/material";
import React from "react";
import { List } from "rsuite";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled, useTheme } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
   focused: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
         borderColor: "green", // Customize the focus color here
      },
   },
   // backgroundColor: "#fff",
   "& .MuiInputBase-root": {
      // backgroundColor: "#fff",
      ":hover": { backgroundColor: "button.texthover" },
   },
});

function ProfileDrawer({ open, handleClose, profileData }) {
   const theme = useTheme();
   return (
      <Dialog onClose={handleClose} open={open} p={2}>
         <Box p={2} display="flex" justifyContent="space-between">
            <Typography sx={{ color: theme.palette.text.primary }}>
               Business Profile
            </Typography>

            <CancelIcon
               onClick={() => handleClose()}
               sx={{ color: "text.primary", cursor: "pointer" }}
            />
         </Box>
         <Box pl={2} pr={2} pb={2} display="flex" justifyContent="center">
            <Avatar
               sx={{ height: "115px", width: "115px" }}
               src={profileData?.profile_picture_url}
            />
         </Box>
         <Box pl={2} pr={2} pb={3}>
            <CustomTextField
               label="About"
               id="filled-start-adornment"
               value={profileData?.about}
               focused
               fullWidth
               sx={{
                  // backgroundColor: "#fff",
                  "& .MuiInputBase-root": {
                     // backgroundColor: "#fff",
                     ":hover": { backgroundColor: "button.texthover" },
                  },
               }}
               variant="filled"
            />
            <CustomTextField
               label="Address"
               id="filled-start-adornment"
               fullWidth
               sx={{
                  // backgroundColor: "#fff",
                  "& .MuiInputBase-root": {
                     // backgroundColor: "#fff",
                     ":hover": { backgroundColor: "button.texthover" },
                  },
               }}
               variant="filled"
            />
            <CustomTextField
               label="Description"
               id="filled-start-adornment"
               fullWidth
               sx={{
                  // backgroundColor: "#fff",
                  "& .MuiInputBase-root": {
                     // backgroundColor: "#fff",
                     ":hover": { backgroundColor: "button.texthover" },
                  },
               }}
               variant="filled"
            />
            <CustomTextField
               focused
               label="Email"
               id="standard-start-adornment"
               fullWidth
               value={profileData?.email}
               variant="filled"
            />
         </Box>
      </Dialog>
   );
}

export default ProfileDrawer;
