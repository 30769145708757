import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   otpEmail: null,
   userId:null
};
const userSlice = createSlice({
   name: "user",
   initialState,
   reducers: {
      setOtpEmail(state, action) {
         state.otpEmail = action.payload;
      },
      setUser(state, action) {
         state.userId = action.payload;
      },
   },
});

export default userSlice.reducer;

export const { setOtpEmail,setUser } = userSlice.actions;
