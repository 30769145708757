import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUserFromStorage } from "../store/slices/authSlice";
import ProtectedRoute from "./ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute";
import AppLayout from "../pages/layout.js/AppLayout";

const Campaigns = lazy(() => import("../pages/campaigns"));

const NewLogin = lazy(() => import("../pages/Login/NewLogin"));
const ForgotPwd = lazy(() => import("../pages/Login/NewForgotPassword"));
const ResetPwd = lazy(() => import("../pages/Login/NewResetPassword"));
const OTPVerification = lazy(() => import("../pages/Login/OTPVerification"));

const Conversations = lazy(() =>
   import("../pages/reports/Insights/Conversations")
);
const Broadcasts = lazy(() => import("../pages/reports/Insights/Broadcasts"));
const Messages = lazy(() => import("../pages/reports/Insights/Messages"));
const UserMain = lazy(() => import("../pages/users/UserMain"));
const Permissions = lazy(() => import("../pages/permission/Permissions"));
const WpBusinessAccount = lazy(() =>
   import("../pages/wp-channel-business/whatsapp-business/wp-businessmainpage")
);
const WpChannel = lazy(() =>
   import("../pages/wp-channel-business/whatsapp-channel/wp-channelmainpage")
);
const SheduledBulkSend = lazy(() =>
   import("../pages/reports/bulkSend/SheduledBulkSend")
);
const Groups = lazy(() => import("../pages/groups/Groups"));
const EditTemplates = lazy(() => import("../pages/templates/EditTemplates"));
const ConverSationLabels = lazy(() =>
   import("../pages/settings/ConverSationLabels")
);
const Insights = lazy(() => import("../pages/reports/Insights/Insights"));
const ResetPassword = lazy(() => import("../pages/Login/ResetPassword"));
const ForgotPassword = lazy(() => import("../pages/Login/ForgotPassword"));
const BulkSendDetail = lazy(() =>
   import("../pages/reports/bulkSend/BulkSendDetail")
);
const BulkSendReport = lazy(() =>
   import("../pages/reports/bulkSend/BulkSendReport")
);
const NotFound = lazy(() => import("../pages/others/NotFound"));
const LoginPage = lazy(() => import("../pages/Login/LoginPage"));
const CreateTemplate = lazy(() => import("../pages/templates/CreateTemplate"));
const TemplatesList = lazy(() => import("../pages/templates/TemplatesList"));
const Contacts = lazy(() => import("../pages/contacts/Contacts"));
const WhatsappChat = lazy(() => import("../pages/chat/WhatsappChat"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));

const Body = () => {
   const theme = useTheme();

   const appRouter = createBrowserRouter([
      //New pages of Login section - start
      {
         path: "/",
         element: <ProtectedRoute />,
         children: [
            {
               path: "/",
               element: <AppLayout />, // Use Layout here
               children: [
                  {
                     path: "/",
                     element: <Dashboard />,
                  },
                  {
                     path: "/chat",
                     element: <WhatsappChat />,
                  },
                  //New pages of Login section - end

                  // {
                  //   path: "/forgot-password",
                  //   element: <ForgotPassword />,
                  // },

                  {
                     path: "/contacts",
                     element: <Contacts />,
                  },
                  {
                     path: "/campaign/new-campaign",
                     element: <Campaigns />,
                  },
                  {
                     path: "/templates",
                     element: <TemplatesList />,
                  },
                  {
                     path: "/templates/create-template",
                     element: <CreateTemplate />,
                  },
                  {
                     path: "/edit-template/:id",
                     element: <EditTemplates />,
                  },
                  {
                     path: "/bulk-send",
                  },
                  {
                     path: "/campaign/report",
                     element: <BulkSendReport />,
                  },
                  // {
                  //   path: "/reset-password",
                  //   element: <ResetPassword />,
                  // },
                  {
                     path: "/bulk-send-view/:id",
                     element: <BulkSendDetail />,
                  },
                  {
                     path: "/insights",
                     element: <Insights />,
                  },
                  {
                     path: "/insights/broadcasts",
                     element: <Broadcasts />,
                  },
                  {
                     path: "/insights/conversations",
                     element: <Conversations />,
                  },
                  {
                     path: "/insights/messages",
                     element: <Messages />,
                  },
                  {
                     path: "/settings/conversation-labels",
                     element: <ConverSationLabels />,
                  },
                  {
                     path: "/settings/groups",
                     element: <Groups />,
                  },
                  {
                     path: "/templates/scheduled-messages",
                     element: <SheduledBulkSend />,
                  },
                  {
                     path: "/channels/whatsapp-channel",
                     element: <WpChannel />,
                  },
                  {
                     path: "/permissions",
                     element: <Permissions />,
                  },
                  {
                     path: "/users",
                     element: <UserMain />,
                  },
                  {
                     path: "/channels/whatsapp-business-account",
                     element: <WpBusinessAccount />,
                  },
               ],
            },
         ],
      },
      {
         path: "/auth",
         element: <UnprotectedRoute />,
         children: [
            {
               path: "sign-in",
               element: <NewLogin />,
            },
            {
               path: "forgotPassword",
               element: <ForgotPwd />,
            },
            {
               path: "reset-password",
               element: <ResetPwd />,
            },
            {
               path: "otp-verification",
               element: <OTPVerification />,
            },
         ],
      },

      {
         path: "*",
         element: <NotFound />,
      },
   ]);
   return (
      <div style={{ backgroundColor: theme.palette.background.body }}>
         <RouterProvider router={appRouter} />
      </div>
   );
};

export default Body;
