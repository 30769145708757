import React, { useState } from "react";
import Sidebar from "../../components/dashboard/NavbarContainer";
import { Outlet } from "react-router-dom";

function AppLayout() {
   const [openSidebar, setOpenSidebar] = useState(false);

   const toggleDrawer = (event) => {
      setOpenSidebar(!openSidebar);
   };
   return (
      <div style={{ display: "flex" }}>
         <Sidebar openSidebar={openSidebar} toggleDrawer={toggleDrawer} />
         <Outlet/>
      </div>
   );
}

export default AppLayout;
