import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: false,
  reducers: {
    setSidebar: (state, action) => {
      return !state
    },
  },
});

export default sidebarSlice.reducer;
export const { setSidebar } = sidebarSlice.actions;
