import { SvgIcon } from "@mui/material";
import React from "react";

function TemplateIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M19.7235 10.5363C16.5124 12.5349 12.3129 15.1488 10.3095 16.3957C10.1196 16.5137 9.87978 16.5141 9.68993 16.3957C6.67712 14.5205 3.73926 12.6919 0.275864 10.5363C-0.093277 10.3067 -0.0909348 9.76993 0.275864 9.54181L1.19227 8.9715C8.96599 13.8098 -4.5543 5.39442 9.07079 13.875C9.63915 14.2289 10.3599 14.2293 10.9286 13.875C25.7597 4.64376 8.88985 15.1439 18.8071 8.9715L19.7235 9.54181C20.0927 9.7715 20.0903 10.3082 19.7235 10.5363Z"
               fill="currentColor"
            />
            <path
               d="M19.7236 7.02071C15.1336 9.87739 13.4915 10.8996 10.3096 12.8801C10.1197 12.9981 9.87987 12.9985 9.69003 12.8801C3.03436 8.73747 7.46589 11.4958 0.275959 7.02071C-0.0900574 6.79297 -0.0927925 6.2586 0.273224 6.02774L9.68729 0.0902345C9.87831 -0.0300782 10.1213 -0.0300782 10.3123 0.0902345L19.7264 6.02774C20.0924 6.2586 20.0896 6.79297 19.7236 7.02071Z"
               fill="currentColor"
            />
            <path
               d="M0.275864 13.0575L1.19227 12.4872L9.07079 17.3907C9.63915 17.7446 10.3599 17.745 10.9286 17.3907L18.8071 12.4872L19.7235 13.0575C20.0927 13.2872 20.0903 13.8239 19.7235 14.052L10.3095 19.9114C10.1196 20.0294 9.87978 20.0298 9.68993 19.9114L0.275864 14.052C-0.093277 13.8223 -0.0909348 13.2856 0.275864 13.0575Z"
               fill="currentColor"
            />
         </svg>
      </SvgIcon>
   );
}

export default TemplateIcon;
