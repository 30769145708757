import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../../config/axios";

export const fetchInsights = createAsyncThunk(
   "insights/fetchInsights",
   async ({ startDate, endDate }) => {
      try {
         const response = await API.get(
            // `reports/analytics/conversation?start=1704091350&&end=1724222550&&granularity=MONTHLY`
            `reports/analytics/conversation?start=${startDate}&&end=${endDate}&&granularity=DAILY`
         );
         return response.data.data;
      } catch (err) {
         throw err;
      }
   }
);

export const fetchLastWeekAnalytics = createAsyncThunk(
   "insights/fetchLastWeekAnalytics",
   async () => {
      try {
         const response = await API.get(`reports/last-week`);
         return response.data.data;
      } catch (err) {
         throw err;
      }
   }
);

const initialState = {
   loading: null,
   error: null,
   reports: [],
   lastWeekAnalytics: {},
};
const insightSlice = createSlice({
   name: "insights",
   initialState,
   reducers: {
      resetState: (state) => {
         state.reports = [];
      },
   },

   extraReducers: (builder) => {
      builder
         .addCase(fetchInsights.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchInsights.fulfilled, (state, action) => {
            state.loading = false;
            state.reports = action.payload;
         })
         .addCase(fetchInsights.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         })
         .addCase(fetchLastWeekAnalytics.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchLastWeekAnalytics.fulfilled, (state, action) => {
            state.loading = false;
            state.lastWeekAnalytics = action.payload;
         })
         .addCase(fetchLastWeekAnalytics.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },
});

export default insightSlice.reducer;
export const { setSidebar, resetState } = insightSlice.actions;
