import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../config/axios";

export const getAllScheduledMessages = createAsyncThunk(
   "scheduledMessage/getAllScheduledMessages",
   async () => {
      try {
         const response = await API.get(`/messages/bulk-send`);
         return response?.data?.data;
      } catch (err) {
         return err;
      }
   }
);

const initialState = {
   loading: false,
   isError: false,
   isSuccess: false,
   message: "",
   scheduledMsg: [],
};

const scheduledMessageSlice = createSlice({
   name: "scheduledMessage",
   initialState,
   reducers: {
      reset: (state) => {
         state = initialState;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllScheduledMessages.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllScheduledMessages.fulfilled, (state, action) => {
            state.loading = false;
            state.scheduledMsg = action.payload;
            state.isSuccess = true;
         })
         .addCase(getAllScheduledMessages.rejected, (state, action) => {
            state.loading = false;
            state.isError = true;
            state.message = action.error.message;
         });
   },
});

export const { reset } = scheduledMessageSlice.actions;
export default scheduledMessageSlice.reducer;
