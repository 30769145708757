import React from "react";
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";

function LogOutModal({ open, onClose, onConfirm }) {
   const theme = useTheme();

    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '400px', 
            maxWidth: '100%', 
          },
        }}
      >
         <DialogTitle>Confirm Logout</DialogTitle>
         <DialogContent>
            <Typography>Are you sure you want to Logout?</Typography>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose}
            sx={{
               color: theme.palette.text.lightgrey,
               '&:hover': {
                  backgroundColor: 'transparent',
                 color: theme.palette.text.grey,
               },
             }}
            >Cancel</Button>
            <Button onClick={onConfirm} 
            sx={{
               marginLeft: "-1px",
               color: theme.palette.text.primary,
               '&:hover': {
                 backgroundColor: 'transparent',
                 color: "#ff0000",
               },
             }}>
               Logout
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default LogOutModal;
