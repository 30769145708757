import { SvgIcon } from "@mui/material";
import React from "react";

function GroupIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M13 13C14.0609 13 15.0783 13.4214 15.8284 14.1716C16.5786 14.9217 17 15.9391 17 17V18.5C17 18.8978 16.842 19.2794 16.5607 19.5607C16.2794 19.842 15.8978 20 15.5 20H3.5C3.10218 20 2.72064 19.842 2.43934 19.5607C2.15804 19.2794 2 18.8978 2 18.5V17C2 15.9391 2.42143 14.9217 3.17157 14.1716C3.92172 13.4214 4.93913 13 6 13H13ZM19 13C19.7956 13 20.5587 13.3161 21.1213 13.8787C21.6839 14.4413 22 15.2044 22 16V17.5C22 17.8978 21.842 18.2794 21.5607 18.5607C21.2794 18.842 20.8978 19 20.5 19H19V17C19 16.2238 18.8193 15.4582 18.4721 14.7639C18.125 14.0697 17.621 13.4657 17 13H19ZM9.5 3C10.6935 3 11.8381 3.47411 12.682 4.31802C13.5259 5.16193 14 6.30653 14 7.5C14 8.69347 13.5259 9.83807 12.682 10.682C11.8381 11.5259 10.6935 12 9.5 12C8.30653 12 7.16193 11.5259 6.31802 10.682C5.47411 9.83807 5 8.69347 5 7.5C5 6.30653 5.47411 5.16193 6.31802 4.31802C7.16193 3.47411 8.30653 3 9.5 3ZM18 6C18.7956 6 19.5587 6.31607 20.1213 6.87868C20.6839 7.44129 21 8.20435 21 9C21 9.79565 20.6839 10.5587 20.1213 11.1213C19.5587 11.6839 18.7956 12 18 12C17.2044 12 16.4413 11.6839 15.8787 11.1213C15.3161 10.5587 15 9.79565 15 9C15 8.20435 15.3161 7.44129 15.8787 6.87868C16.4413 6.31607 17.2044 6 18 6Z"
               fill="currentColor"
            />
         </svg>
      </SvgIcon>
   );
}

export default GroupIcon;
