import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalNotificationCount: 0,
  count: 0,
  chatIds: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // addNotificaton(state, action) {
    //   state.count = state.count + 1;
    //   const isExistChat = state.chatIds[action.payload];
    //   if (!isExistChat) {
    //     state.chatIds = [...state.chatIds, action.payload];
    //     localStorage.setItem("chatIds", JSON.stringify(state.chatIds));
    //   }
    //   localStorage.setItem("notification", state.count);
    // },
    addNotificaton(state, action) {
      const { chatId, count } = action.payload;
      // if the chat ids are empty add to chat id array
      if (state.chatIds.length === 0) {
        const newChat = [{ id: chatId, count: count }];
        state.chatIds = newChat;
      } else {
        // If chatids exist match the id and add the message count
        //Check if chat id is exist or not
        const isChatExist = state?.chatIds?.find((chat) => chat.id === chatId);

        if (isChatExist) {
          const newChatIds = state.chatIds?.map((chat) => {
            if (chat.id === chatId) {
              chat.count = count;
            }
            return chat;
          });
          state.chatIds = newChatIds;
        } else {
          state.chatIds = [...state.chatIds, { id: chatId, count: count }];
        }
      }
    },
    removeNotification(state, action) {
      if (state.count !== 0) {
        const isExistChatID = state.chatIds.find((id) => id === action.payload);
        if (isExistChatID) {
          state.chatIds = state.chatIds.filter((id) => id !== action.payload);

          localStorage.setItem("chatIds", JSON.stringify(state.chatIds));
          state.count = state.count - 1;
          localStorage.setItem("notification", state.count);
        }
      }
    },
  },
});

export default notificationSlice.reducer;
export const { addNotificaton, removeNotification } = notificationSlice.actions;
