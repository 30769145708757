import {
   Box,
   Collapse,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   styled,
   Typography,
} from "@mui/material";
import React, { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RolesIcon from "../../assets/icons/svg/SidebarRoles";
import SummarizeIcon from '@mui/icons-material/Summarize';
//icons
import BookIcon from "../../assets/icons/png/book.png";
import ChatbotIcon from "../../assets/icons/png/chatbot.png";
import ConnectionIcon from "../../assets/icons/png/connection.png";
import GrowthIcon from "../../assets/icons/png/growth.png";
// import HomeIcon from "../../assets/icons/png/home.png";
// import LogoutIcon from "../../assets/icons/png/logout.png";
import MessageIcon from "../../assets/icons/png/message.png";
import SettingsIcon from "../../assets/icons/svg/SidebarSettings";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import TelegramIcon from "@mui/icons-material/Telegram";
import LabelIcon from "@mui/icons-material/Label";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DehazeIcon from "@mui/icons-material/Dehaze";
import SearchIcon from "@mui/icons-material/Search";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArticleIcon from "@mui/icons-material/Article";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import CampaignIcon from '@mui/icons-material/Campaign';
import HomeIcon from "../../assets/icons/svg/SidebarHome";
import InboxIcon from "../../assets/icons/svg/SidebarInbox";
import ScheduledMessageIcon from "../../assets/icons/svg/SidebarScheduled";
import { ThemeContext } from "../../theme";

import AddUser from "../../assets/icons/png/adduser.png";

import { useTheme } from "@emotion/react";
import TemplateIcon from "../../assets/icons/svg/SidebarTemplates";
import ReportIcon from "../../assets/icons/svg/SidebarReport";
import BulkSendIcon from "../../assets/icons/svg/SidebarBulkSend";
import ChannelIcon from "../../assets/icons/svg/SidebarChannels";
import BusinessAccountIcon from "../../assets/icons/svg/SidebarBusiness";
import InsightIcon from "../../assets/icons/svg/SidebarInsight";
import ConversationIcon from "../../assets/icons/svg/SidebarConversation";
import ConversationLabelIcon from "../../assets/icons/svg/SidebarLabels";
import GroupIcon from "../../assets/icons/svg/SidebarGroups";
import LogOutModal from "./LogOutModal";
import { useSelector } from "react-redux";

const SubListItem = styled(ListItemButton)({
   "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
      // height: "40px",
      alignItems: "center",
      // paddingLeft:"18px"
   },
});

function SideBarList({ open, handleLogout }) {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const { previleges,rolename } = useSelector((state) => state.auth);

   const theme = useTheme();
   const isDarkMode = theme.palette.mode === "dark";
   const navigate = useNavigate();
   const location = useLocation();

   const [openMenus, setOpenMenus] = useState({
      channels: false,
      settings: false,
      templates: false,
      reports: false,
      insights: false,
   });

   const handleToggleMenu = (menu) => {
      setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
   };

   //LOGOUT FUNCTIONALITY - START
   const handleLogoutClick = () => {
      setIsModalOpen(true); // Open the modal
   };

   const handleModalClose = () => {
      setIsModalOpen(false); // Close the modal
   };

   const handleLogoutConfirm = () => {
      handleLogout(); // Call the existing handleLogout function
      setIsModalOpen(false);
   };

   const isSelected = (path) => location.pathname === path;
   const isMainPage = (path) => location.pathname.startsWith(path);

   const sidebarItems = [
      {
         label: "Dashboard",
         icon: (
            <HomeIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isSelected("/")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
         ),
         path: "/",
         privilegeKey: "dashboard",
      },
      {
         label: "Inbox",
         icon: (
            <InboxIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isSelected("/chat")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
            // <img
            //    src={MessageIcon}
            //    alt="Inbox"
            //    style={{
            //       filter: isSelected("/chat")
            //          ? isDarkMode
            //             ? "none"
            //             : "invert(1) brightness(0) saturate(100%)"
            //          : isDarkMode
            //          ? "invert(1) brightness(10) saturate(100%)"
            //          : "none",
            //    }}
            // />
         ),
         path: "/chat",
         privilegeKey: "inbox",
      },
      {
         label: "Templates",
         icon: (
            <TemplateIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isMainPage("/templates")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
         ),
         path: "/templates",
         submenu: [
            {
               label: "Templates List",
               path: "/templates",
               icon: (
                  <FormatListBulletedIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/templates")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "listTemplate",
            },
            {
               label: "Create Templates",
               path: "/templates/create-template",
               icon: (
                  <CreateNewFolderIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/templates/create-template")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "createTemplate",
            },
            {
               label: "Scheduled Messages",
               path: "/templates/scheduled-messages",
               icon: (
                  <ScheduledMessageIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/templates/scheduled-messages")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "scheduledMessages",
            },
         ],
         stateKey: "templates",
      },
      {
         label: "Campaigns",
         icon: (
            <CampaignIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isMainPage("/campaign")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
         ),
         path: "/campaign",
         submenu: [
            {
               label: "New Campaign",
               path: "/campaign/new-campaign",
               icon: (
                  <SummarizeIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/campaign/new-campaign")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "newCampaign",
            },
            {
               label: "Campaign Report",
               path: "/campaign/report",
               icon: (
                  <BulkSendIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/campaign/report")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "campaignReport",
            },
         ],
         stateKey: "campaign",
      },
      {
         label: "Channels",
         icon: (
            <ChannelIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isMainPage("/channels")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
         ),
         path: "/channels",
         submenu: [
            {
               label: "WhatsApp Channels",
               path: "/channels/whatsapp-channel",
               icon: (
                  <WhatsAppIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/channels/whatsapp-channel")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "whatsappChannel",
            },
            {
               label: "Business Account",
               path: "/channels/whatsapp-business-account",
               icon: (
                  <BusinessAccountIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/channels/whatsapp-business-account")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "businessAccount",
            },
         ],
         stateKey: "channels",
      },
      {
         label: "Insights",
         icon: (
            <InsightIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isMainPage("/insights")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
         ),
         privilegeKey: "insights",
         path: "/insights",
         submenu: [
            {
               label: "Conversation",
               path: "/insights/conversations",
               icon: (
                  <ConversationIcon
                     sx={{
                        fontSize: "1.22rem",
                        color: isSelected("/insights/conversations")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "conversation",
            },
            
         ],
         stateKey: "insights",
      },
      {
         label: "Users",
         icon: (
            <img
               src={AddUser}
               alt="Users"
               style={{
                  filter: isSelected("/users")
                     ? isDarkMode
                        ? "none"
                        : "invert(1) brightness(0) saturate(100%)"
                     : isDarkMode
                     ? "invert(1) brightness(10) saturate(100%)"
                     : "none",
               }}
            />
         ),
         path: "/users",
         privilegeKey: "users",
      },
      {
         label: "Roles",
         icon: (
            <RolesIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isSelected("/permissions")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
         ),
         path: "/permissions",
         privilegeKey: "roles",
      },
      {
         label: "Settings",
         icon: (
            <SettingsIcon
               sx={{
                  fontSize: "1.22rem",
                  color: isMainPage("/settings")
                     ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                     : isDarkMode
                     ? "white"
                     : "#0292d0",
               }}
            />
         ),
         path: "/settings",
         privilegeKey: "settings",
         submenu: [
            {
               label: "Conversation Labels",
               path: "/settings/conversation-labels",
               icon: (
                  <ConversationLabelIcon
                     sx={{
                        fontSize: "1.22rem",

                        color: isSelected("/settings/conversation-labels")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "conversationLabels",
            },
            {
               label: "Groups",
               path: "/settings/groups",
               icon: (
                  <GroupIcon
                     sx={{
                        fontSize: "1.22rem",

                        color: isSelected("/settings/groups")
                           ? isDarkMode
                              ? "#0292d0"
                              : "#002539"
                           : isDarkMode
                           ? "white"
                           : "#0292d0",
                     }}
                  />
               ),
               privilegeKey: "groups",
            },
         ],
         stateKey: "settings",
      },
      {
         label: "Logout",
         icon: <LogoutIcon sx={{ color: "#ff0000", fontSize: "1.22rem" }} />,
         action: handleLogoutClick,
         // action: handleLogout,
         color: "#ff0000",
      },
   ];

   const filteredSidebarItems = sidebarItems.filter((item) => {
      
      const privileges = previleges || {};
      const hasMainPrivilege = item.privilegeKey
         ? item.privilegeKey in privileges
            ? privileges[item.privilegeKey]
            : true // Default to true if privilegeKey doesn't exist in privileges
         : true;

      // Check submenu privileges if exists
      if (item.submenu) {
         item.submenu = item.submenu.filter((subItem) =>
            subItem.privilegeKey ? privileges[subItem.privilegeKey] : true
         );
      }

      // Return true if the main item or any of its submenu items are accessible
      return hasMainPrivilege || (item.submenu && item.submenu.length > 0);
   });

   return (
      <>
         <List>
            {filteredSidebarItems.map((item) =>{
               
               return (
               <React.Fragment key={item.label}>
                  <Box sx={{ display: "flex" }}>
                     <Box
                        width={8}
                        height="auto"
                        bgcolor={
                           item.privilegeKey === "dashboard"
                              ? isSelected(item.path)
                                 ? isDarkMode
                                    ? "#0292d0"
                                    : "#002539"
                                 : isDarkMode
                                 ? "#202020"
                                 : "#fff"
                              : isMainPage(item.path)
                              ? isDarkMode
                                 ? "#0292d0"
                                 : "#002539"
                              : isDarkMode
                              ? "#202020"
                              : "#fff"
                        }
                        sx={{ borderRadius: "0px 10px 10px 0px" }}
                     ></Box>
                     <ListItemButton
                        onClick={
                           item.submenu
                              ? () => handleToggleMenu(item.stateKey)
                              : item.action || (() => navigate(item.path))
                        }
                        selected={isSelected(item.path)}
                        sx={{
                           "& .MuiListItemIcon-root": {
                              minWidth: "30px !important",
                              height: "40px",
                              alignItems: "center",
                           },
                           "&.Mui-selected": {
                              backgroundColor: "background.primary", // Change this to your desired background color for selected items
                              color: "secondary.contrastText", // Change this to your desired text color for selected items
                              "& .MuiListItemIcon-root": {
                                 color: "secondary.contrastText", // Change the icon color for selected items
                              },
                           },
                        }}
                     >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText
                           primary={item.label}
                           primaryTypographyProps={{ fontSize: "0.95rem" }}
                           sx={{
                              opacity: open ? 1 : 0,
                              color: isSelected(item.path)
                                 ? "text.sidebaractive"
                                 : "primary.main",
                           }}
                        />
                     </ListItemButton>
                  </Box>
                  {item.submenu && (
                     <Collapse
                        in={openMenus[item.stateKey]}
                        timeout="auto"
                        unmountOnExit
                     >
                        <List component="div" disablePadding>
                           {item.submenu.map((subItem) => (
                              <SubListItem
                                 key={subItem.label}
                                 sx={{ pl: 4 }}
                                 selected={isSelected(subItem.path)}
                                 onClick={() => navigate(subItem.path)}
                              >
                                 <ListItemIcon
                                    sx={{
                                       color: isSelected(item.path)
                                          ? "text.sidebaractive"
                                          : "primary.main",
                                    }}
                                 >
                                    {subItem.icon}
                                 </ListItemIcon>
                                 <ListItemText
                                    primary={subItem.label}
                                    primaryTypographyProps={{
                                       fontSize: "0.95rem",
                                    }}
                                    sx={{
                                       color: isSelected(subItem.path)
                                          ? "text.sidebaractive"
                                          : "primary.main",
                                    }}
                                 />
                              </SubListItem>
                           ))}
                        </List>
                     </Collapse>
                  )}
               </React.Fragment>
            )})}
         </List>

         <LogOutModal
            open={isModalOpen}
            onClose={handleModalClose}
            onConfirm={handleLogoutConfirm}
         />
      </>
   );
}

export default SideBarList;
