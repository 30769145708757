import { SvgIcon } from "@mui/material";
import React from "react";

function ConversationIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
         >
            <mask
               id="mask0_333_22"
               style={{maskType:"alpha"}}
               maskUnits="userSpaceOnUse"
               x="0"
               y="0"
               width="20"
               height="20"
            >
               <rect width="20" height="20" fill="url(#pattern0_333_22)" />
            </mask>
            <g mask="url(#mask0_333_22)">
               <rect x="-64" y="-18" width="100" height="100" fill="currentColor" />
            </g>
            <defs>
               <pattern
                  id="pattern0_333_22"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
               >
                  <use xlinkHref="#image0_333_22" transform="scale(0.05)" />
               </pattern>
               <image
                  id="image0_333_22"
                  width="20"
                  height="20"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADuSURBVHgBzZPNDYJAEIXfDHg0sQRswJ9O7MAKOFCBUIEm0IelGGgASiDRmwnjrsYoP0sW8OC7sNmZfLzdN0swyEnSvQid1HIBGxEKAodkqnOS5RB4GKaSjaXhMK0F48f6f6DbTLPyV4QJYgGFsB0NG+DINHuATR3zt9sSQyVStICue9voLwkC3QBbCUpyqog4zqRWUE+ous+3CJa9DjlOQ9V80OvvILnjTx7PrhcnznZ9wMpfh6o5au676LbvKdtn5R5m4Io0VDmt7U8e7JfTOnBUmqYSj03TVK49s0/iEjWPYquOOxwPa9OfszVNDzXhWA+6+phdAAAAAElFTkSuQmCC"
               />
            </defs>
         </svg>
      </SvgIcon>
   );
}

export default ConversationIcon;
