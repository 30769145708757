import { SvgIcon } from "@mui/material";
import React from "react";

function BulkSendIcon(props) {
   return (
      <SvgIcon {...props}>
         <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
         >
            <mask
               id="mask0_333_19"
               style={{maskType:"alpha"}}
               maskUnits="userSpaceOnUse"
               x="0"
               y="0"
               width="24"
               height="24"
            >
               <rect width="24" height="24" fill="url(#pattern0_333_19)" />
            </mask>
            <g mask="url(#mask0_333_19)">
               <rect x="-38" y="-20" width="100" height="100" fill="currentColor" />
            </g>
            <defs>
               <pattern
                  id="pattern0_333_19"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
               >
                  <use
                     xlinkHref="#image0_333_19"
                     transform="scale(0.0416667)"
                  />
               </pattern>
               <image
                  id="image0_333_19"
                  width="24"
                  height="24"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH9SURBVHgB7VVBctMwFP1fcjwepgw9grcsmjYnwD4BvUHbJUNC0xOQnqBpwwxLnBuUEyRHIOmCJe4NPGmYCbGlz1dCi21KI5Vh17exLX2/p///k4RQQvDxa7hU+QgIQnCAQOgX7ebJvXPljzzPI1dyA03Q/ducgP+MJ4GN8DYFoFBH6m0rgUfiYQGE5Pmz7cvZxaTL79vgAN/zk8Wbl+mDAoJwOJvPeoB4DI74kS/3+LFv0wOnld9CCHxhnpUMhFRflBant99FoVLpyUsCSkthIdfu9R/CXE4guuaNesAZh7+HHwFvcBVpoFF5THeaKy4xmCRMe4AIY9Vuxt568Oo9APWs2BHSIt9qSe9mTADRpnBv/Q9FBJYwZ5WfhaTFdbkA8sP0E5kSIb4CqgnwwJBjIxt+kzosIa0bnAgOV4K1la5cpN41EyQ4suAfmrrCSSsDS9z1QBVb5w0/YxdJ07x7rInnurPT5dhjXtVEg7YSWGVgeiC8+UiKIJNCxZxvWg2j01/kxgx9cEBpo9Geuc2MiFa6JGLId3tOTiuh2ip2iBEJgiBeLBaxaIh93d7ti4vpGU9Wbi0ErG/AClSuk3UcQw6mo4qn2eu+bMQslM1uvp8B6kOopI1x0dkZg3MG5UyK/Ntynmeup2gdmw67fyK/E+Ad+Nn6D26+bXkMfgLPPs43JsfbuwAAAABJRU5ErkJggg=="
               />
            </defs>
         </svg>
      </SvgIcon>
   );
}

export default BulkSendIcon;
