import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../../config/axios";

export const getBulkSendReport = createAsyncThunk("bulkSend/getBulkSendReport", async () => {
  try {
    const response = await API.get(`/reports/bulksend-report`);
    return response.data.data;
  } catch (err) {
    throw err
  }
});

const initialState = {
  loading: null,
  error: null,
  reports: [],
};
const bulkSendReportSlice = createSlice({
  name: "bulkSend",
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      return !state;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBulkSendReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBulkSendReport.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(getBulkSendReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export default bulkSendReportSlice.reducer;
export const { setSidebar } = bulkSendReportSlice.actions;
