import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

function FullScreenSpinner() {
   return (
      <div display="flex">
         <Backdrop
            sx={{ color: "text.primary", zIndex: (theme) => theme.zIndex.drawer + 1,backgroundColor:"background.primary" }}
            open={true}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
      </div>
   );
}

export default FullScreenSpinner;
